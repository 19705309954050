import { gotoEvent, showMsg, elId, getQueryVariable, ERROR_MESSAGES } from './utils-module.js';

const scanCardInput = elId('scanCardInput');
const scanCardAct = elId('scanCardAct');
const attId = getQueryVariable('attId');
if (attId) {
  scanCardInput.value = attId;
}

function isValid(code) {
  return code.length > 4 && code.length <= 32;
}

function normalizeCode(code) {
  return code.replaceAll(' ', '');
}

scanCardAct.addEventListener('click', () => {
  if (isValid(scanCardInput.value)) {
    gotoEvent(normalizeCode(scanCardInput.value));
  } else {
    showMsg(ERROR_MESSAGES.valid_att_code);
  }
});

scanCardInput.addEventListener('keyup', (event) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    if (isValid(scanCardInput.value)) {
      gotoEvent(normalizeCode(scanCardInput.value));
    } else {
      showMsg(ERROR_MESSAGES.valid_att_code);
    }
  }
});
